<template>
  <div class="form p-12">
    <!-- 第一部分 -->
    <div class="f-500 flex items-center">
      <div class="mr-4 "> <span style="color:red">*</span>Type:</div>
      <el-select v-model="dataForm.Type" :disabled="disabled"  size="small">
        <el-option label="Discharging" value="Discharging"></el-option>
        <el-option label="Loading" value="Loading"></el-option>
        <el-option label="Ballast" value="Ballast"></el-option>
        <el-option label="Bunkering" value="Bunkering"></el-option>
      </el-select>
    </div>
    <!-- 表格 -->
    <el-table :data="dataForm.list" class="mt-4">
      <el-table-column label="Loading" prop="formDescription" width="600">
        <template slot-scope="scope">
          <span v-if="checkNotRequired( scope.row.formDescription )" style="padding-left:9px;"></span>
          <span v-else style="color:red">*</span>
          {{scope.row.formDescription}}
        </template>
      </el-table-column>
      <el-table-column label="Date and Time" min-width="300">
        <template slot-scope="scope">
          <div class="flex ">
            <el-date-picker v-model="scope.row.getTime" :disabled="disabled || checkDisabled( scope.$index )" class="mr-4" prefix-icon="el-icon-date"
                            size="mini"
                            type="datetime" :editable="false"></el-date-picker>
            <el-button size="mini" @click="fillCurrentTime(scope.row)" :disabled="disabled || checkDisabled( scope.$index )">Now</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="f-600 black mt-4 flex justify-between">
      <div style="height: 30px;">
        <el-button size="small" type="primary" @click="add" :disabled="disabled">Add</el-button>
      </div>
    </div>
    <el-table :data="dataForm.listItem" class="mt-4">
      <el-table-column label="Cause of Delay" min-width="500">
        <template slot-scope="scope">
          <el-input v-model="scope.row.causeOfDelay" :disabled="disabled"  size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Date and Time(From)" min-width="250">
        <template slot-scope="scope">
          <el-date-picker v-model="scope.row.fromTime" :disabled="disabled" :editable="false" prefix-icon="el-icon-date"
                          size="mini"
                          type="datetime"></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="Date and Time(To)" min-width="250">
        <template slot-scope="scope">
          <el-date-picker v-model="scope.row.toTime" :disabled="disabled" :editable="false" prefix-icon="el-icon-date"
                          size="mini"
                          type="datetime"></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <i v-if="dataForm.listItem.length>1 && !disabled" class="el-icon-circle-close cursor-pointer fs-15" style="color: red"
             @click="delCause(scope.$index)"></i>
        </template>
      </el-table-column>
    </el-table>
    <!--
    <div style="color: red;" class="mt-4 mb-4 fs-12">
      *Please fill in at least 1 product and its qualities.
    </div>
    -->
    <div class="mt-8 f-600" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";
import dayjs from "dayjs";

export default {
  name: "TimeSheet",
  props: {
    disabled: {
      type: Boolean
    },
    sign:{
      type:Object
    },
    beforeAfterFlag: {
      type: Number,
      default: 1,
    },
    nominationData: {
      type:Object
    }
  },
  data() {
    return {
      dataForm: {},
      params: {},
      beforeLength: 9
    }
  },
  async mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      await this.getDetail()
    }
  },
  methods: {
    delCause(index) {
      this.dataForm.listItem.splice(index, 1)
    },
    save() {
      const data = {
        ...this.dataForm,
        nominationId: this.params.id,
        list: this.dataForm.list.map(item => {
          return {
            ...item,
            nominationId: this.params.id,
          }
        }),
        listItem: this.dataForm.listItem.map(item => {
          return {
            ...item,
            nominationId: this.params.id,
          }
        }),
        beforeAfterFlag: this.beforeAfterFlag
      }
      
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    add() {
      this.dataForm.listItem.push({})
    },
    fillCurrentTime(row) {
      // console.log(row)
      row.getTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
    },
    async getDetail() {
      await selectFromById({
        nId: this.params.id,
        fId: 18
      }).then(res => {
        this.dataForm = {...res.data}
        this.dataForm.list.forEach(item => { item.beforeAfterFlag = item.orderIndex <= this.beforeLength ? 0 : 1 });
        this.dataForm.listItem = this.dataForm.listItem && this.dataForm.listItem.length > 0 ? this.dataForm.listItem : [{}]
      })
    },
    filterOutFirst3Last3( index ) {
      return this.isForPilot ? index >= 3 && index < (this.dataForm.list.length - 3) : true
    },
    checkDisabled( index ) {
      return this.filterOutFirst3Last3( index) ? 
              (
                (this.beforeAfterFlag == 0 && index >= this.beforeLength) || 
                (this.beforeAfterFlag == 1 && index < this.beforeLength)
              ) : true
    },
    checkNotRequired( description ) {
      const notRequired = [
        'Notice of readiness tendered',
        'Notice of readiness accepted',
        'Pilot on board for sailing',
        'Started unmooring',
        'Vessel all clear',
      ]
      return notRequired.includes( description )
    },
  },
  computed: {
    isForPilot() {
      return this.nominationData.typeOfPilot == 'IHP'
    }
  },
}
</script>


<style scoped lang="scss">
.form {
  font-size: 15px;
  color: #82889C;
  background-color: #fff;
}
</style>
